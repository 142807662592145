import { insertIf } from 'forms/DECOUVERTE_PROSPECT/Risques/CommunUtil';
import FormDemandeAdhesionStepFrais from 'forms/DEMANDE_ADHESION/FormDemandeAdhesionStepFrais.component';
import React from 'react';
import { FormConfigWithUrl, StepDescriptionList } from 'types';
import LeftPaneDescription from '../components/LeftPane/LeftPaneDescription.component';
import FormDemandeAdhesionStepInfos from '../forms/DEMANDE_ADHESION/FormDemandeAdhesionStepInfos.component';
import FormDemandeAdhesionStepInit from '../forms/DEMANDE_ADHESION/FormDemandeAdhesionStepInit.component';
import FormDemandeAdhesionStepOffre from '../forms/DEMANDE_ADHESION/FormDemandeAdhesionStepOffre.component';
import FormDemandeAdhesionStepPJ from '../forms/DEMANDE_ADHESION/FormDemandeAdhesionStepPJ.component';
import FormDemandeAdhesionStepSignature from '../forms/DEMANDE_ADHESION/FormDemandeAdhesionStepSignature.component';

enum STEP {
  INIT = 'INIT',
  OFFRE = 'OFFRE',
  INFOS = 'INFOS',
  PJ = 'PJ',
  FRAIS = 'FRAIS',
  SIGNATURE = 'SIGNATURE'
}
type PrevStepStateType = {
  numeroDevisChoisi: string;
  stepCode: string;
  assureurCode: string;
};

const leftPaneDescriptions: StepDescriptionList = {
  STEP_OFFRE: {
    title: 'Bon à savoir',
    description:
      'Afin de connaitre le détail de chaque offre, vous pouvez télécharger les documents du projet (section “Documentation” ci-contre). Pour sélectionner l’offre qui vous convient cliquer directement sur le bouton “Choisir” sous l’offre souhaitée.'
  },
  STEP_INFOS: {
    title: 'Bon à savoir',
    description:
      'Afin de constituer votre dossier d’adhésion, vous devez nous fournir les informations complémentaires suivantes.'
  },
  STEP_PJ: {
    title: 'Bon à savoir',
    description:
      'Afin de constituer votre dossier d’adhésion, vous devez nous fournir les pièces justificatives ainsi que vos coordonnées bancaires pour le prélèvement automatique de votre cotisation d’assurance.'
  },
  STEP_FRAIS: {
    title: 'Frais de dossier',
    description:
      'Les frais de dossier sont facturés une seule fois, en amont de votre souscription. Ils correspondent à la rémunération du temps passé par notre cabinet à élaborer votre projet.'
  },
  STEP_SIGNATURE: {
    title: 'Bon à savoir',
    description:
      "En cliquant sur “J’accède à la signature en ligne”, vous allez être redirigé vers l’espace de signature électronique afin de signer les documents liés à votre demande d'adhésion."
  }
};

function getBreadCrumbConf(withFrais: boolean, withInfos: boolean) {
  return {
    steps: [
      {
        stepCode: STEP.OFFRE,
        libelle: 'Devis'
      },
      ...insertIf(withInfos, {
        stepCode: STEP.INFOS,
        libelle: 'Informations Complémentaires'
      }),
      {
        stepCode: STEP.PJ,
        libelle: 'Pièces complémentaires'
      },
      ...insertIf(withFrais, {
        stepCode: STEP.FRAIS,
        libelle: 'Frais de dossier'
      }),
      {
        stepCode: STEP.SIGNATURE,
        libelle: 'Signature'
      }
    ]
  };
}

function getFormSteps(withFrais: boolean) {
  return [
    {
      component: FormDemandeAdhesionStepInit,
      stepCode: STEP.INIT,
      noBreadCrumb: true
    },
    {
      component: FormDemandeAdhesionStepOffre,
      description: <LeftPaneDescription descriptionObj={leftPaneDescriptions.STEP_OFFRE} />,
      stepCode: STEP.OFFRE,
      submit: true,
      breadcrumbBelowPrecButton: true,
      next: {
        selectNextStep: (prevStepState: PrevStepStateType) => {
          if (prevStepState?.assureurCode === 'ROEDERER') {
            return 'INFOS';
          } else {
            return 'WITHOUT_INFOS';
          }
        },
        paths: {
          INFOS: [
            {
              component: FormDemandeAdhesionStepInfos,
              description: <LeftPaneDescription descriptionObj={leftPaneDescriptions.STEP_INFOS} />,
              stepCode: STEP.INFOS,
              submit: true,
              breadcrumbBelowPrecButton: true
            },
            ...getStepAfterInfos(withFrais)
          ],
          WITHOUT_INFOS: getStepAfterInfos(withFrais)
        }
      }
    }
  ];
}

const getStepAfterInfos = (withFrais: boolean) => {
  return [
    {
      component: FormDemandeAdhesionStepPJ,
      description: <LeftPaneDescription descriptionObj={leftPaneDescriptions.STEP_PJ} />,
      stepCode: STEP.PJ,
      submit: true,
      formData: true,
      breadcrumbBelowPrecButton: true
    },
    ...insertIf(withFrais, {
      component: FormDemandeAdhesionStepFrais,
      description: <LeftPaneDescription descriptionObj={leftPaneDescriptions.STEP_FRAIS} />,
      stepCode: STEP.FRAIS,
      submit: true,
      breadcrumbBelowPrecButton: true
    }),
    {
      component: FormDemandeAdhesionStepSignature,
      description: <LeftPaneDescription descriptionObj={leftPaneDescriptions.STEP_SIGNATURE} />,
      stepCode: STEP.SIGNATURE,
      submit: true,
      breadcrumbBelowPrecButton: true
    }
  ];
};

function getStepOrder(withFrais: boolean, withInfo: boolean) {
  return [
    STEP.INIT,
    STEP.OFFRE,
    ...insertIf(withInfo, STEP.INFOS),
    STEP.PJ,
    ...insertIf(withFrais, STEP.FRAIS),
    STEP.SIGNATURE
  ];
}

export const demandeAdhesionSansFraisSansInfos: FormConfigWithUrl = {
  endpoint: 'adhesion',
  isTitleLeftCommercial: true,
  displayMobileFooter: true,
  breadCrumbConf: getBreadCrumbConf(false, false),
  avecRetour: true,
  stepOrder: getStepOrder(false, false),
  formSteps: getFormSteps(false)
};
export const demandeAdhesionSansFraisAvecInfos: FormConfigWithUrl = {
  endpoint: 'adhesion',
  isTitleLeftCommercial: true,
  displayMobileFooter: true,
  breadCrumbConf: getBreadCrumbConf(false, true),
  avecRetour: true,
  stepOrder: getStepOrder(false, true),
  formSteps: getFormSteps(false)
};

export const demandeAdhesionAvecFraisAvecInfos: FormConfigWithUrl = {
  endpoint: 'adhesion',
  isTitleLeftCommercial: true,
  displayMobileFooter: true,
  breadCrumbConf: getBreadCrumbConf(true, true),
  avecRetour: true,
  stepOrder: getStepOrder(true, true),
  formSteps: getFormSteps(true)
};
export const demandeAdhesionAvecFraisSansInfos: FormConfigWithUrl = {
  endpoint: 'adhesion',
  isTitleLeftCommercial: true,
  displayMobileFooter: true,
  breadCrumbConf: getBreadCrumbConf(true, false),
  avecRetour: true,
  stepOrder: getStepOrder(true, false),
  formSteps: getFormSteps(true)
};
