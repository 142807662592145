import { yupResolver } from '@hookform/resolvers/yup';
import { merge } from 'lodash';
import React, { ReactElement, useEffect, useState } from 'react';
import FormConsent from '../../../components/FormConsent/FormConsent.component';
import FormNextButton from '../../../components/FormNextButton/FormNextButton.component';
import InputText from '../../../components/Input/InputText/InputText.component';
import InputTextAutoComplete from '../../../components/Input/InputText/InputTextAutoComplete';
import { ErrorLabel } from '../../../components/Input/parts/ErrorLabel/ErrorLabel.component';
import { fetchPaysOptions, fetchVilleOptions, OptionsFetch } from '../../../fetches/options.fetch';
import { useFetch } from '../../../hooks/useFetch.hook';
import { useFetchOptionsOnFieldChange } from '../../../hooks/useFetchOptionsOnFieldChange.hook';
import { useSerieForm } from '../../../hooks/useSerieForm.hook';
import { AccumulateStateToSerie, FormProps, SerieSubmitStatus } from '../../../types';
import InputDateSelectMonthDay from '../ReglesGestion/InputDateSelectMonthDay/InputDateSelectMonthDay.component';
import { PrevoyanceTNS } from '../ReglesGestion/PrevoyanceTNS';

const AccumulateStateToSeries: AccumulateStateToSerie = (serieState, state) => {
  return merge(serieState, state);
};

export const FormDemandeAdhesionStepInfosPrevoyanceTNS = (props: FormProps<any>): ReactElement => {
  const { initialFormValues } = props;
  const [isLoading, setIsLoading] = useState(false);

  const initialValues = PrevoyanceTNS.getInitialFormValues(initialFormValues);

  const {
    handleSubmit,
    watch,
    setValue,
    control,
    formState: { errors, isValid },
    register
  } = useSerieForm<any>(props.setLastFormState, {
    resolver: yupResolver(PrevoyanceTNS.validationSchema as any),
    defaultValues: initialValues || PrevoyanceTNS.defaultState
  });

  const villeOptions = useFetchOptionsOnFieldChange(watch, 'codePostalNaissance', fetchVilleOptions);
  const paysOptions = useFetch(fetchPaysOptions);
  const metiers = useFetchOptionsOnFieldChange(
    watch,
    'professionLibelle',
    OptionsFetch.fetchMetiersOptions,
    input => !!input && input.length > 2
  );

  const categoriesSocioProfessionnelles = useFetchOptionsOnFieldChange(
    watch,
    'professionCode',
    OptionsFetch.fetchCategorieByMetierOptions
  );

  useEffect(() => {
    setValue('categorieSocioProfessionelleLibelle', categoriesSocioProfessionnelles[0]?.label);
    setValue('categorieSocioProfessionelleCode', categoriesSocioProfessionnelles[0]?.value);
  }, [categoriesSocioProfessionnelles]);

  useEffect(() => {
    setValue('stepCode', 'INFOS');
  }, []);

  const handleNextClick = (data: any) => {
    props.goNextStep(data, AccumulateStateToSeries);
  };

  return (
    <div className="form-adhesion">
      <div className={'adhesion-step-infos'}>
        <h2>
          Informations complémentaires <br />
          <small className="form-font-regular label-pj">
            Afin de compléter votre demande d&apos;adhésion, nous avons besoin des informations suivantes :
          </small>
        </h2>
        {
          <form
            onSubmit={(...props) => {
              setIsLoading(isValid);
              return handleSubmit(handleNextClick)(...props);
            }}
          >
            <InputText
              label={"Numéro de sécurité sociale de l'assuré principal"}
              {...register('numeroSecuriteSocial')}
              errors={errors}
            />

            <InputText label={'Code postal de naissance'} {...register('codePostalNaissance')} errors={errors} />

            <InputTextAutoComplete
              label={'Ville de naissance'}
              name="villeNaissance"
              control={control}
              errors={errors}
              options={villeOptions}
            />

            <InputTextAutoComplete
              label={'Pays de naissance'}
              name="paysNaissance"
              control={control}
              errors={errors}
              options={paysOptions || []}
            />

            <input {...register('professionCode')} hidden />

            <InputTextAutoComplete
              label="Métier"
              name="professionLibelle"
              control={control}
              options={metiers || []}
              errors={errors}
              onSuggestionSelected={suggestion => {
                setValue('professionLibelle', suggestion.label);
                setValue('professionCode', suggestion.value);
              }}
            />

            <input {...register('categorieSocioProfessionelleCode')} hidden />

            <input {...register('categorieSocioProfessionelleLibelle')} hidden />

            <InputDateSelectMonthDay
              label="Date de clôture de l'exercice"
              name="dateClotureExercice"
              control={control}
            />

            <input {...register('stepCode')} hidden />

            <div className="form-consentement-container">
              <FormConsent
                contenuConsentement={props.contenuConsentement}
                control={control}
                errors={errors}
                label={props.cocheConsentement}
                {...register('consentement')}
              />
            </div>

            <div className="next-button-container">
              <FormNextButton
                showCaptcha={props.showCaptcha}
                setCaptchaToken={props.setCaptchaToken}
                loading={isLoading}
              >
                {props.readOnly ? 'Suivant' : 'Je valide ces informations'}
              </FormNextButton>
              {props.submitIntermediaryStepState?.status === SerieSubmitStatus.FAILED && (
                <div className="error-submit">
                  <ErrorLabel>{props.submitIntermediaryStepState?.message}</ErrorLabel>
                </div>
              )}
            </div>
          </form>
        }
      </div>
    </div>
  );
};
