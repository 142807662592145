import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { yupResolver } from '@hookform/resolvers/yup';
import { ReactComponent as SvgTime } from 'assets/time.svg';
import FormConsent from 'components/FormConsent/FormConsent.component';
import React, { ReactElement, useEffect, useMemo, useState } from 'react';
import { assignValuesFromSource, getUrlParam } from 'utils';
import FormNextButton from '../../components/FormNextButton/FormNextButton.component';
import useCallApi from '../../hooks/useCallAPI.hook';
import { useGenerateProcedureSignature } from '../../hooks/useMandatSepa.hook';
import { useSerieForm } from '../../hooks/useSerieForm.hook';
import { FormProps } from '../../types';
import parseSearch from '../../utils/parseSearch';
import Yup from '../../utils/Yup';
import './FormMandatSepaSignature.scss';
import FormMandatSepaFooter from './FormMandatSepaFooter.component';

interface FormValues {
  consentement?: boolean;
}

const defaultState: FormValues = {
  consentement: false
};
const validationSchema: Yup.SchemaOf<FormValues> = Yup.object({
  consentement: Yup.boolean().isTrue('Vous devez obligatoirement cocher cette case pour valider votre demande.')
}).defined();

function getInitialValues(initialFormValues?: { [key: string]: any }): Record<string, any> {
  return assignValuesFromSource(defaultState, initialFormValues || {}, (item: any) => item);
}

type ProcedureResponse = {
  procedureSignature: {
    urlSignature: string;
    etatCode: string;
    numero: string;
  };
};

export default function FormMandatSepaSignature(props: FormProps<FormValues>): ReactElement {
  const initialValues = useMemo(() => getInitialValues(props.previousFormValues), []);
  const {
    handleSubmit,
    register,
    control,
    formState: { errors, isValid }
  } = useSerieForm<FormValues>(props.setLastFormState, {
    resolver: yupResolver(validationSchema),
    defaultValues: (props.formValues as FormValues) || initialValues || defaultState
  });

  const [numeroProcedure, setNumeroProcedure] = useState<string>('');
  const [urlProcedure, setUrlProcedure] = useState<string>('');

  const id = parseSearch(window.location.search, 'id');

  const handleNextClick = async (data: FormValues) => {
    window.open(urlProcedure, '_blank');
    await props.goNextStep(data);
  };

  const getProcedureSignatureFromAPI = useCallApi<any, any>(
    () =>
      fetch(
        `${process.env.REACT_APP_URL_API_FORMULAIRE}formulaires/${getUrlParam(
          location.search,
          'id'
        )}/procedure/${numeroProcedure}`,
        {
          method: 'get',
          headers: { 'Content-Type': 'application/json' }
        }
      ),
    (data: ProcedureResponse, successCallback) => {
      successCallback?.(data);
    },
    err => {
      console.log(err);
    }
  );

  const handleSuccessGetProcedure = (setter: any) => (data: ProcedureResponse) => {
    if (
      !data.procedureSignature ||
      (data && data.procedureSignature.etatCode !== 'SIGNATURE_EN_ATTENTE:ETAT_PROCEDURE')
    ) {
      getProcedureSignatureFromAPI({ numeroProcedure: numeroProcedure }, handleSuccessGetProcedure(setUrlProcedure));
    } else {
      setter(data.procedureSignature.urlSignature);
    }
  };

  useEffect(() => {
    let si: NodeJS.Timeout;

    if (numeroProcedure === '') {
      useGenerateProcedureSignature({
        idFormulaire: id,
        numeroClient: props.previousFormValues.numeroClient
      })
        .then(numero => {
          setNumeroProcedure(numero);
        })
        .catch(err => {
          console.error(err);
          throw err;
        });
    }
    return () => clearInterval(si);
  }, [numeroProcedure]);

  useEffect(() => {
    if (urlProcedure === '' && numeroProcedure !== '') {
      getProcedureSignatureFromAPI({ numeroProcedure: numeroProcedure }, handleSuccessGetProcedure(setUrlProcedure));
    }
  }, [numeroProcedure, urlProcedure]);

  return (
    <form onSubmit={handleSubmit(handleNextClick)} className="formMandatSepaSignature">
      <div className="formMandatSepaSignature__justify">
        <p className="title formMandatSepaSignature__title">Il ne vous reste plus qu’à signer !</p>
        <p>
          Vous venez de recevoir un mail pour finaliser la signature en ligne.
          <br />
          Vous pourrez signer ces documents après consultation et vérification de votre identité au moyen d&apos;un code
          de sécurité envoyé sur votre téléphone portable.
          <br />
          Si vous ne signez pas dans les 24h suivants la réception de l&apos;email, vous devrez vous authentifier par le
          biais d&apos;un code temporaire qui vous sera adressé par email lorsque vous souhaiterez accéder à la page de
          signature Yousign.
        </p>
        <div className="formMandatSepaSignature__paddingTop30">
          En signant ce formulaire de mandat, vous autorisez (A) la mutuelle TUTÉLAIRE à envoyer des instructions à
          votre banque pour débiter votre compte, et (B) votre banque à débiter votre compte conformément aux
          instructions de la mutuelle TUTÉLAIRE. Vous bénéficiez du droit d’être remboursé par votre banque selon les
          conditions décrites dans la convention que vous avez passée avec elle. Une demande de remboursement doit être
          présentée :
          <ul>
            <li>dans les 8 semaines suivant la date de débit de votre compte pour un prélèvement autorisé ;</li>
            <li> sans tarder et au plus tard dans les 13 mois en cas de prélèvement non autorisé</li>
          </ul>
          Note : vos droits concernant le présent mandat sont expliqués dans un document que vous pouvez obtenir auprès
          de votre banque.
        </div>
        <div className="formMandatSepaSignature__creancier">
          <p>
            <b>Créancier</b> :Tutélaire – 157 avenue de France – 75013 Paris{' '}
          </p>
          <p>
            Identifiant créancier SEPA : <b>FR88ZZZ386329</b>
          </p>
        </div>
        <p className="formMandatSepaSignature__text">
          Au clic sur le bouton <b>Accéder à la signature en ligne</b>, vous serez redirigé vers le site sécurisé de
          notre partenaire Yousign.
        </p>

        <FormConsent
          contenuConsentement={props.contenuConsentement}
          control={control}
          errors={errors}
          label={props.cocheConsentement}
          {...register('consentement')}
        />
        {urlProcedure === '' && (
          <div className="formMandatSepaSignature__waitingProcedureSignature">
            <SvgTime />
            <p>Merci de patienter encore quelques instants, votre procédure de signature sera bientôt disponible. </p>
          </div>
        )}

        {urlProcedure === undefined && (
          <div className="formMandatSepaSignature__waitingProcedureSignature">
            <FontAwesomeIcon icon={faExclamationTriangle} />
            <p>
              Un problème est intervenu lors de la création de votre procédure de signature, veuillez nous contacter.
            </p>
          </div>
        )}

        {urlProcedure && (
          <FormNextButton
            className="formMandatSepaSignature__submit"
            showCaptcha={props.showCaptcha}
            setCaptchaToken={props.setCaptchaToken}
            disabled={!isValid}
          >
            Accéder à la signature en ligne
          </FormNextButton>
        )}
        <FormMandatSepaFooter />
      </div>
    </form>
  );
}
