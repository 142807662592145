import { yupResolver } from '@hookform/resolvers/yup';
import _ from 'lodash';
import React, { ReactElement, useEffect, useMemo } from 'react';
import FieldUploadFile from '../../components/FieldUploadFile/FieldUploadFile';
import FormNextButton from '../../components/FormNextButton/FormNextButton.component';
import InputRadioButtonInline from '../../components/Input/InputRadioButton/InputRadioButtonInline.component';
import { DOCUMENT_IDENTITE_OPTIONS } from '../../consts/consts';
import {
  useGenerateMandatSepa,
  useGetExistingDocumentMandatSepa,
  useUpsertDocumentMandatSepa
} from '../../hooks/useMandatSepa.hook';
import { useSerieForm } from '../../hooks/useSerieForm.hook';
import { DocumentsSouscription, FormProps } from '../../types';
import { assignValuesFromSource, createFormData } from '../../utils';
import parseSearch from '../../utils/parseSearch';
import Yup from '../../utils/Yup';
import FormMandatSepaFooter from './FormMandatSepaFooter.component';
import './FormMandatSepaImportDocuments.scss';

interface FormValues {
  documents?: Record<string, DocumentsSouscription>;
  typeDocumentIdentite?: string;
}

const defaultState: FormValues = {
  typeDocumentIdentite: '',
  documents: {
    cniRecto: {
      document: {
        fileName: '',
        fileUploadedName: ''
      }
    },
    cniVerso: {
      document: {
        fileName: '',
        fileUploadedName: ''
      }
    },
    passeport: {
      document: {
        fileName: '',
        fileUploadedName: ''
      }
    },
    rib: {
      document: {
        fileName: '',
        fileUploadedName: ''
      }
    }
  }
};

const enum CategorieDocument {
  PIECE_IDENTITE_RECTO = 'PIECE_IDENTITE_RECTO',
  PIECE_IDENTITE_VERSO = 'PIECE_IDENTITE_VERSO',
  PASSEPORT = 'PASSEPORT',
  RIB = 'RIB',
  PERMIS = 'PERMIS',
  TITRE_SEJOUR = 'TITRE_SEJOUR'
}

const validationSchema: Yup.SchemaOf<FormValues> = Yup.object({
  documents: Yup.object(),
  typeDocumentIdentite: Yup.string().required()
}).defined();

const getTargetFile = (libelleType: string): string => {
  switch (libelleType) {
    case CategorieDocument.PIECE_IDENTITE_RECTO:
      return 'cniRecto';
    case CategorieDocument.PIECE_IDENTITE_VERSO:
      return 'cniVerso';
    case CategorieDocument.PASSEPORT:
      return 'passport';
    case CategorieDocument.RIB:
      return 'rib';
    case CategorieDocument.PERMIS:
      return 'permis';
    case CategorieDocument.TITRE_SEJOUR:
      return 'titreSejour';
    default:
      return '';
  }
};

function getInitialValues(initialFormValues?: { [key: string]: any }): Record<string, any> {
  return assignValuesFromSource(defaultState, initialFormValues || {}, (item: any) => item);
}

export default function FormMandatSepaImportDocuments(props: FormProps<FormValues>): ReactElement {
  const [loading, setLoading] = React.useState(false);
  const initialValues = useMemo(() => getInitialValues(props.previousFormValues), []);

  const {
    handleSubmit,
    register,
    formState: { errors },
    watch,
    setValue,
    getValues,
    trigger
  } = useSerieForm<FormValues>(props.setLastFormState, {
    resolver: yupResolver(validationSchema),
    defaultValues: (props.formValues as FormValues) || initialValues || defaultState
  });

  useEffect(() => {
    useGetExistingDocumentMandatSepa({
      idFormulaire: parseSearch(window.location.search, 'id'),
      numeroClient: props.previousFormValues.numeroClient
    }).then(documents => {
      _.map(documents, (d: any) => {
        setValue(`documents.${getTargetFile(d.libelleType)}.document.fileUploadedName`, d.fileUploadedName);
      });
    });
  }, []);

  const handleNextClick = async (data: FormValues) => {
    setLoading(true);
    await useGenerateMandatSepa({
      idFormulaire: parseSearch(window.location.search, 'id'),
      numeroClient: props.previousFormValues.numeroClient,
      rib: {
        iban: props.previousFormValues.iban,
        bic: props.previousFormValues.bic
      }
    });
    setLoading(false);
    props.goNextStep(data);
  };

  const handleUpload = async (file: File, categorie: string) => {
    const id = parseSearch(window.location.search, 'id');
    const data = {
      fileContent: file,
      fileUploadedName: file.name
    };

    const fd = createFormData(data);

    await useUpsertDocumentMandatSepa({
      document: fd,
      idFormulaire: id,
      numeroClient: props.previousFormValues.numeroClient,
      categorie: categorie
    });
  };

  const blocPassport = (
    <FieldUploadFile
      className={'file-input form-font-regular'}
      label="Passeport"
      onFileUploaded={(file: any) => {
        handleUpload(file, CategorieDocument.PASSEPORT);
        setValue('documents.passeport.document.fileUploadedName', file.name);
        trigger('documents.passeport.document.fileUploadedName');
      }}
      onFileDelete={() => {
        setValue('documents.passeport.document.fileUploadedName', '');
        trigger('documents.passeport.document.fileUploadedName');
      }}
      value={getValues('documents.passeport')}
      errors={errors}
      register={register}
      name={`documents.passeport`}
      readOnly={props.readOnly}
    />
  );
  const blocCni = (
    <>
      <FieldUploadFile
        className={'file-input form-font-regular'}
        label="Carte d'identité recto"
        onFileUploaded={(file: any) => {
          handleUpload(file, CategorieDocument.PIECE_IDENTITE_RECTO);
          setValue('documents.cniRecto.document.fileUploadedName', file.name);
          trigger('documents.cniRecto.document.fileUploadedName');
        }}
        onFileDelete={() => {
          setValue('documents.cniRecto.document.fileUploadedName', '');
          trigger('documents.cniRecto.document.fileUploadedName');
        }}
        value={getValues('documents.cniRecto')}
        errors={errors}
        register={register}
        name={`documents.cniRecto`}
        readOnly={props.readOnly}
      />
      <FieldUploadFile
        className={'file-input form-font-regular'}
        label="Carte d'identité verso"
        onFileUploaded={(file: any) => {
          handleUpload(file, CategorieDocument.PIECE_IDENTITE_VERSO);
          setValue('documents.cniVerso.document.fileUploadedName', file.name);
          trigger('documents.cniVerso.document.fileUploadedName');
        }}
        onFileDelete={() => {
          setValue('documents.cniVerso.document.fileUploadedName', '');
          trigger('documents.cniVerso.document.fileUploadedName');
        }}
        value={getValues('documents.cniVerso')}
        errors={errors}
        register={register}
        name={`documents.cniVerso`}
        readOnly={props.readOnly}
      />
    </>
  );
  const blocRib = (
    <FieldUploadFile
      className={'file-input form-font-regular'}
      label="Relevé d'Identité Bancaire"
      onFileUploaded={(file: any) => {
        handleUpload(file, CategorieDocument.RIB);

        setValue('documents.rib.document.fileUploadedName', file.name);
        trigger('documents.rib.document.fileUploadedName');
      }}
      onFileDelete={() => {
        setValue('documents.rib.document.fileUploadedName', '');
        trigger('documents.rib.document.fileUploadedName');
      }}
      value={getValues('documents.rib')}
      errors={errors}
      register={register}
      name={`documents.rib`}
      readOnly={props.readOnly}
    />
  );
  const blocPermis = (
    <FieldUploadFile
      className={'file-input form-font-regular'}
      label="Permis de conduire"
      onFileUploaded={(file: any) => {
        handleUpload(file, CategorieDocument.PERMIS);

        setValue('documents.permis.document.fileUploadedName', file.name);
        trigger('documents.permis.document.fileUploadedName');
      }}
      onFileDelete={() => {
        setValue('documents.permis.document.fileUploadedName', '');
        trigger('documents.permis.document.fileUploadedName');
      }}
      value={getValues('documents.permis')}
      errors={errors}
      register={register}
      name={`documents.permis`}
      readOnly={props.readOnly}
    />
  );
  const blocTitreSejour = (
    <FieldUploadFile
      className={'file-input form-font-regular'}
      label="Titre de séjour en cours de validité"
      onFileUploaded={(file: any) => {
        handleUpload(file, CategorieDocument.TITRE_SEJOUR);

        setValue('documents.titreSejour.document.fileUploadedName', file.name);
        trigger('documents.titreSejour.document.fileUploadedName');
      }}
      onFileDelete={() => {
        setValue('documents.titreSejour.document.fileUploadedName', '');
        trigger('documents.titreSejour.document.fileUploadedName');
      }}
      value={getValues('documents.titreSejour')}
      errors={errors}
      register={register}
      name={`documents.titreSejour`}
      readOnly={props.readOnly}
    />
  );

  const cniRecto = watch('documents.cniRecto.document.fileUploadedName');
  const cniVerso = watch('documents.cniVerso.document.fileUploadedName');
  const rib = watch('documents.rib.document.fileUploadedName');
  const passport = watch('documents.passport.document.fileUploadedName');
  const permis = watch('documents.permis.document.fileUploadedName');
  const titreSejour = watch('documents.titreSejour.document.fileUploadedName');
  const typeDocumentIdentite = watch('typeDocumentIdentite');

  return (
    <form onSubmit={handleSubmit(handleNextClick)} className="formMandatSepaImportDocuments">
      <p className="title formMandatSepaImportDocuments__title">Importez vos documents</p>
      <p className="formMandatSepaImportDocuments__text">
        Pour enregistrer vos modifications de coordonnées bancaires, en conformité avec la réglementation en vigueur,
        nous vous demandons <b>d’importer la copie d’une pièce d’identité</b> (Carte Nationale d’Identité, passeport,
        permis de conduire ou titre de séjour) recto-verso en cours de validité et <b>la copie d’un RIB à votre nom</b>.
        <br />
        <br />
        Formats acceptés : PDF, PNG, JPEG, JPG.
        <br />
        Le poids maximum de tous vos justificatifs ne doit pas excéder 5 Mo.
      </p>

      <InputRadioButtonInline
        labelField={"Quel type de pièce d'identité souhaitez-vous importer ?"}
        {...register(`typeDocumentIdentite`)}
        options={DOCUMENT_IDENTITE_OPTIONS}
        column={2}
        errors={errors}
      />
      {typeDocumentIdentite === 'CNI' && blocCni}
      {typeDocumentIdentite === 'PASSEPORT' && blocPassport}
      {typeDocumentIdentite === 'PERMIS' && blocPermis}
      {typeDocumentIdentite === 'TITRE_SEJOUR' && blocTitreSejour}
      {typeDocumentIdentite !== '' && blocRib}
      {rib != '' &&
        ((typeDocumentIdentite === 'PASSEPORT' && passport != '') ||
          (typeDocumentIdentite === 'CNI' && cniRecto != '' && cniVerso != '') ||
          (typeDocumentIdentite === 'PERMIS' && permis != '') ||
          (typeDocumentIdentite === 'TITRE_SEJOUR' && titreSejour != '')) && (
          <>
            <FormNextButton showCaptcha={props.showCaptcha} setCaptchaToken={props.setCaptchaToken} loading={loading}>
              Suivant
            </FormNextButton>
            <FormMandatSepaFooter />
          </>
        )}
    </form>
  );
}
